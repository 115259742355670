import React, { useEffect, useState } from 'react'
import { useAuth } from '../Auth/Provider'
import styles from './Projects.module.scss'
import { Link } from 'react-router-dom'

type Project = {
  id: string
  name: string
}

function Projects(): React.JSX.Element {
  const { getToken } = useAuth()

  const [projects, setProjects] = useState<Project[] | null>(null)

  useEffect(() => {
    getToken().then((token) => {
      fetch('/api/v1/projects', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
        .then((response) => response.json())
        .then((data) => setProjects(data))
    })
  }, [getToken])

  return (
    <>
      <div data-testid="projects">
        <h1>Проекты</h1>
        {projects !== null ? (
          <div className={styles.projects}>
            {projects.map((project) => (
              <div key={project.id} className={styles.project}>
                <Link to={'/projects/' + project.id}>{project.name}</Link>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </>
  )
}

export default Projects
