import React from 'react'
import NotFound from './NotFound'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthProvider } from './Auth/Provider'
import Auth from './Auth'
import Dashboard from './Dashboard/Dashboard'
import Projects from './Dashboard/Projects'
import Project from './Project/Project'

function App() {
  return (
    <AuthProvider
      authorizeUrl={process.env.REACT_APP_AUTH_URL + '/auth'}
      tokenUrl={process.env.REACT_APP_AUTH_URL + '/token'}
      clientId="frontend"
      scope="common"
      redirectPath="/auth"
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashboard />}>
            <Route path="/" element={<Projects />} />
          </Route>
          <Route path="/projects/:id" element={<Project />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  )
}

export default App
