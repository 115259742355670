type onOpenEventCallback = (event: Event) => void
type onMessageEventCallback = (event: MessageEvent) => void
type onErrorEventCallback = (event: Event) => void

class WebSocketClient {
  private ws: WebSocket | null = null
  private onOpenCallbacks: onOpenEventCallback[] = [(event) => console.log(event)]
  private onMessageCallbacks: onMessageEventCallback[] = [(event) => console.log(event)]
  private onErrorCallbacks: onErrorEventCallback[] = [(event) => console.error(event)]

  onOpen(callback: onOpenEventCallback): void {
    this.onOpenCallbacks.push(callback)
    if (this.ws === null) {
      this.ws = this.connect()
      return
    }
    this.ws.addEventListener('open', callback)
  }

  onMessage(callback: onMessageEventCallback): void {
    this.onMessageCallbacks.push(callback)

    if (this.ws === null) {
      this.ws = this.connect()
      return
    }

    this.ws.addEventListener('message', callback)
  }

  onError(callback: onErrorEventCallback): void {
    this.onErrorCallbacks.push(callback)
    if (this.ws === null) {
      this.ws = this.connect()
      return
    }
    this.ws.addEventListener('error', callback)
  }

  send(message: object): void {
    if (this.ws === null) {
      this.ws = this.connect()
      this.ws.addEventListener('open', () => this.ws?.send(JSON.stringify(message)))
      return
    }
    this.ws.send(JSON.stringify(message))
  }

  close(): void {
    if (this.ws !== null && this.ws.readyState === WebSocket.OPEN) {
      this.ws.close()
    }
  }

  private connect(): WebSocket {
    const ws = new WebSocket((process.env.REACT_APP_WS_URL || '') + '/client')

    this.onOpenCallbacks.forEach((callback) => ws.addEventListener('open', callback))
    this.onMessageCallbacks.forEach((callback) => ws.addEventListener('message', callback))
    this.onErrorCallbacks.forEach((callback) => ws.addEventListener('error', callback))

    ws.onclose = (event) => {
      if (event.wasClean) {
        console.error('Disconnect', { code: event.code })
      } else {
        console.error('Disconnect')
      }
      setTimeout(() => (this.ws = this.connect()), 200)
    }

    return ws
  }
}

const wsClient = {
  connect: () => new WebSocketClient(),
}

export default wsClient
