import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import { useAuth } from '../Auth/Provider'

function Dashboard(): React.JSX.Element {
  const { isAuthenticated, login, logout } = useAuth()

  if (!isAuthenticated) {
    login()
    return <></>
  }

  return (
    <>
      <div className="wrap">
        <div className="header-bar">
          <div className="container">
            <div className="logo">
              <Link to="/">veveo.ru</Link>
            </div>
            <nav>
              <a
                data-testid="logout-link"
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  logout()
                }}
              >
                Выход
              </a>
            </nav>
          </div>
        </div>
        <div className="container">
          <div data-testid="dashboard">
            <Outlet />
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="container">
          <div>&copy; veveo.ru</div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
